import gsap from "gsap";
import $ from "jQuery";

let data = null;
let current = 0;
let slideLength = 0;
let noCtrl = false;
let timerId = null;
let slideExt = '.jpg';
let isSp;

const baseDuration = 1;
const baseDelay = 0.125;
const slideInterval = 8;
const clipPathStart = "polygon(0% 0%, 0% 0%, -20% 100%, 0px 100%)";
const clipPathEnd = "polygon(0% 0%, 120% 0%, 100% 100%, 0px 100%)";
const clipPathStartSp = "polygon(0% 0%, 0% 0%, -35% 100%, 0px 100%)";
const clipPathEndSp = "polygon(0% 0%, 135% 0%, 100% 100%, 0px 100%)";

const convertBr = (text = "") => {
  let convertText;
  convertText = text.replace("#br#", "<br>");
  convertText = convertText.replace("#brpc#", '<br class="is-Pc_Tab">');
  convertText = convertText.replace("#brsp#", '<br class="is-Sp">');
  return convertText;
};
const checkSupportWebp = () => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src =
      "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    }
  });
};

const getData = () => {
  const data = [];
  $(".mv-slide-data > li").each(function () {
    const d = {
      imgPath: $(this).data("imgpath"),
      imgPathSp: $(this).data("imgpathsp"),
      txt01: $(this).data("txt01"),
      txt02: $(this).data("txt02"),
    };
    data.push(d);
  });
  return data;
};
const onceImgPreLoad = () => {
  data.forEach((element) => {
    const img = document.createElement("img");
    const imgSp = document.createElement("img");
    if(isSp) {
      imgSp.src = element.imgPathSp + slideExt;
    } else {
      img.src = element.imgPath + slideExt;
    }
  });
};

const resetTimer = () => {
  if (!timerId) return;
  clearInterval(timerId);
  timerId = null;
};
const setTimer = () => {
  if (timerId) return;
  timerId = setInterval(next, slideInterval * 1000);
};

const getImagePath = (index) => {
  if (isSp) {
    return data[index].imgPathSp + slideExt;
  } else {
    return data[index].imgPath + slideExt;
  }
};
const getClipPathStart = () => {
  if (isSp) {
    return clipPathStartSp;
  } else {
    return clipPathStart;
  }
};
const getClipPathEnd = () => {
  if (isSp) {
    return clipPathEndSp;
  } else {
    return clipPathEnd;
  }
};
const setIsSp = () => {
  if ($(window).width() > 767) {
    isSp = false;
  } else {
    isSp = true;
  }
};

const createNav = () => {
  data.forEach((element, index) => {
    $(".mv-slide__nav").append(
      `<li class="mv-slide__nav-${index}" data-index="${index}"></li>`
    );
  });
};
const changeNav = (index) => {
  $(`.mv-slide__nav > li`).removeClass("current");
  $(`.mv-slide__nav-${index}`).addClass("current");
};

const getNextIndex = () => {
  let next = current + 1;
  if (next >= slideLength) {
    next = 0;
  }
  return next;
};
const getPrevIndex = () => {
  let prev = current - 1;
  if (0 > prev) {
    prev = slideLength - 1;
  }
  return prev;
};
const setCurrentSlide = (index) => {
  gsap.set(".mv-slide__bg", {
    background: `url(${getImagePath(index)}) no-repeat center center`,
  });
};
const setNextSlide = (index) => {
  gsap.set(".mv-slide__bg--next", {
    background: `url(${getImagePath(index)}) no-repeat center center`,
    filter: "blur(20px)",
    scale: 1.1,
    clipPath: getClipPathStart(),
  });
};
const changeSlide = (callback = () => {}) => {
  noCtrl = true;
  changeCopy(current);
  changeNav(current);
  gsap.to(".mv-slide__bg--next", {
    filter: "blur(0px)",
    scale: 1,
    clipPath: getClipPathEnd(),
    duration: baseDuration,
    onComplete: () => {
      setCurrentSlide(current);
      callback();
      noCtrl = false;
    },
  });
};
const next = () => {
  setNextSlide(getNextIndex());
  current = getNextIndex();
  changeSlide();
};
const prev = () => {
  setNextSlide(getPrevIndex());
  current = getPrevIndex();

  changeSlide();
};
const move = (index) => {
  if (next >= slideLength) return;
  setNextSlide(index);
  current = index;

  changeSlide();
};

const changeCopy = (index = 0) => {
  const d = data[index];
  gsap.to(".mv-slide__catch, .mv-slide__txt", {
    opacity: 0,
    duration: baseDuration / 2,
    onComplete: () => {
      $(".mv-slide__catch").html(convertBr(d.txt01));
      $(".mv-slide__txt").html(d.txt02);
      gsap.set(".mv-slide__catch, .mv-slide__txt", {
        x: 40,
      });
    },
  });
  gsap.to(".mv-slide__catch", {
    opacity: 1,
    x: 0,
    duration: baseDuration / 1.5,
    delay: baseDuration / 2,
  });
  gsap.to(".mv-slide__txt", {
    skewX: 0,
    opacity: 1,
    filter: "blur(0px)",
    x: 0,
    duration: baseDuration / 1.5,
    delay: baseDuration / 2 + baseDelay * 2,
  });
};

export const initMvSlide = () => {
  if (!$("#js-mv-slide").length) return;

  checkSupportWebp().then((isSupport) => {
    if(isSupport) {
      slideExt = '.webp';
    }
    setIsSp();

    data = getData();
    onceImgPreLoad();
    slideLength = data.length;

    createNav();

    setCurrentSlide(current);
    changeCopy(current);
    changeNav(current);

    setTimer();

    $(".mv-slide__nav > li").on("click", function () {
      if (noCtrl) return;
      const index = $(this).data("index");
      if (current === index) return;
      resetTimer();
      move(index);
      setTimeout(setTimer, 1000);
    });
    $(window).on("resize", function () {
      setIsSp();
      setCurrentSlide(current);
    });
  });
};
