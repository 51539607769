const $_serachBoxInput = $('.g-menu .search-box > input');

const open = ($_target) => {
  var $_target_menu = $(".g-menu--" + $_target.data("target"));

  $(".g-menu-wrap").css("height", "auto");

  $(".js-open-menu").removeClass("open");
  $(".g-menu").removeClass("open");

  $(".header").addClass("open");
  $_target.addClass("open");
  $_target_menu.addClass("open");
  $(".js-g-menu").addClass("open");
  $(".g-menu-wrap").css("height", $_target_menu.innerHeight() + 20 + "px");
}
const close = () => {
  $(".header").removeClass("open");
  $(".js-open-menu").removeClass("open");
  $(".js-g-menu").removeClass("open");
}

export function richMenu() {

  let preWidth = $(window).width();

  // リッチメニュー
  $(".js-open-menu").on("click", function (e) {
    e.preventDefault();
    if ($(this).hasClass("open")) {
      close();
    } else {
      var $_target = $(this);
      open($_target);

      const targetMenu = $_target.data("target");
      if(targetMenu === 'search') {
        $_serachBoxInput.focus();
      }
    }
  });

  $(".js-g-menu-close").on("click", function () {
    close();
  });

  $(window).on("resize", function () {
    if($(window).width() === preWidth) return;
    if (767 >= $(window).width()) {
      close();
    }
    let preW = $(window).width();
  });
}
